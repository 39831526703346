<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title> Application Detail </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="refreshAllApp()"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-refresh </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allAppsDetail"
              :custom-filter="filterOnlyCapsText"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Application Detail)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>
                    <v-img
                      :lazy-src="row.item.playStoreImg"
                      max-height="25"
                      max-width="25"
                      :src="row.item.playStoreImg"
                    ></v-img>
                  </td>
                  <td>{{ row.item.applicationName }}</td>
                  <td>{{ row.item.rating }}</td>
                  <td>{{ row.item.downloads }}</td>
                  <td><a :href="row.item.playStoreImg" target="_blank" style="text-decoration: none;"><v-icon color="primary">mdi-eye</v-icon> View</a></td>
                  <td>
                    <v-chip x-small label v-for="tag in row.item.tags" :key="tag" :color="getColor(tag)" dark class="mx-1">
                      {{ tag }}
                    </v-chip>
                  </td>
                  <td style="min-width: 92px;"><span><a @click="updateAppDetailModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a></span></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <update-application :showModel="showUpdateApplicationModel" :application-detail="updateApplicationDetail" @close="updateAppDetail"></update-application>
  </v-container>
</template>
<script>
import axios from "axios";
import UpdateApplication from './UpdateApplication';
export default {
  name: "Applications",
  components: {
    UpdateApplication
  },
  data: () => ({
    search: "",
    allAppsDetail: [],
    headers: [
      { text: "Application Logo"},
      { text: "Application Name", value: "applicationName"},
      { text: "Rating", value: "rating"},
      { text: "Downloads", value: "downloads"},
      { text: "Play Store Img", value: "playStoreImg"},
      { text: "Tags", value: "tags"},
      { text: "Action"},
    ],
    tableLoading: true,
    showUpdateApplicationModel:false,
    updateApplicationDetail:{}
  }),
  computed: {},
  mounted: function () {
    this.getAppsDetail();
  },
  created: function () {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getAppsDetail() {
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/apps")
        .then((res) => {
          const response = res.data;
          this.allAppsDetail = response.data;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    refreshAllApp() {
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/apps/refresh")
        .then((res) => {
          const response = res.data;
          this.allAppsDetail = response.data;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    updateAppDetailModel(appDatail){
      this.updateApplicationDetail = appDatail;
      this.showUpdateApplicationModel = true;
    },
    getColor(isActive) {
      if (isActive) return "green";
      else return "orange";
    },
    updateAppDetail(){
      this.showUpdateApplicationModel = false;
      this.getAppsDetail();
    }
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
</style>
