<template>
  <div class="text-center">
    <v-dialog v-model="showUpdateModel" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Application Detail</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" v-if="showAppDetail">
                  <v-text-field type="number" label="Rating *" :rules="[v => !!v || 'Rating is required',v => v > 0 || 'Please enter 0 to 5 rating',v => v <= 5 || 'Please enter 1 to 5 rating']" v-model="editAppDetailForm.rating" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field type="number" label="Downloads *" :rules="[v => !!v || 'Downloads is required']" v-model="editAppDetailForm.downloads" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-combobox multiple
                    v-model="editAppDetailForm.tags" 
                    label="Tags"
                    append-icon
                    chips
                    deletable-chips
                    class="tag-input"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUpdateAppDetailModel()">
            Close
          </v-btn>
          <v-btn color="primary" @click="updateAppDetail()">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from '../Snackbar.vue';
export default {
  name: "UpdatePiAd",
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    applicationDetail: {
      type: Object,
    },
    imageBase:{
      type: String
    }
  },
  components: {
    Snackbar,
  },
  data: () => ({
    showUpdateModel: false,
    editAppDetailForm:{
      rating:'',
      downloads:'',
      tags:[]
    },
    showAppDetail:true,
    valid: true,
  }),
  watch: {
    showModel(val) {
      this.showUpdateModel = val;
    },
    'applicationDetail': function(value){
        var applicationDetail = value;
        var downloads = applicationDetail.downloads.replaceAll(',','').replaceAll('+','')
        this.editAppDetailForm.downloads = downloads;
        this.editAppDetailForm.rating = applicationDetail.rating;
        this.editAppDetailForm.tags = applicationDetail.tags;
    }
  },
  computed: {},
  mounted: function () {},
  created: function () {},
  methods: {
    resetUpdateAppDetailModel(){
        this.$emit('close');
    },
    updateAppDetail(){
      if(this.$refs.form.validate()){
        var downloads = this.numberWithCommas(this.editAppDetailForm.downloads);
        axios.put(this.Node_JS_HOST + "/api/v1/web/apps",{ _id : this.applicationDetail._id , downloads : downloads, rating : this.editAppDetailForm.rating, tags : this.editAppDetailForm.tags })
        .then((res) => {
            if(res){
                this.resetUpdateAppDetailModel();
                this.snackbarText = 'App Detail Updated.';
                this.isShowSnackbar = true;
            }
        })
        .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = 'Please insert valid input';
            this.isShowSnackbar = true;
        });
      }
    },
    numberWithCommas(number) {
        console.log(number.length);
        var roundNumber = '1'+('').padStart(number.length-2,'0');
        var num = (Math.floor(number / roundNumber) * roundNumber)
        var numberWithComma = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return numberWithComma+'+';
    }
  },
};
</script>